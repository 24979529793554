import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import Loader from 'src/components/Loader';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminAuthGuard from 'src/components/AdminAuthGuard';
import HomeOwnerAuthGuard from './components/HomeOwnerAuthGuard';
import PartnersAuthGuard from './components/PartnersAuthGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  isAdmin?: boolean;
  isHomeOwner?: boolean;
  isPartners?: boolean;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        if (route.isPartners)
          console.log(
            route.isPartners,
            routes.length,
            'UserRoleEnum.PARTNERS === roleUserRoleEnum.PARTNERS === role'
          );

        const Guard = route.guard || Fragment;
        const AdminGard = route.isAdmin ? AdminAuthGuard : Fragment;
        const HomeOwnerGuard = route.isHomeOwner
          ? HomeOwnerAuthGuard
          : Fragment;
        const PartnersOwnerGuard = route.isPartners
          ? PartnersAuthGuard
          : Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component || Fragment;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <AdminGard>
                  <PartnersOwnerGuard>
                    <HomeOwnerGuard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </HomeOwnerGuard>
                  </PartnersOwnerGuard>
                </AdminGard>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/login-link',
    component: lazy(() => import('src/views/auth/LoginWithLink'))
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/blog',
        isAdmin: true,
        component: lazy(() => import('src/views/blog/table-blog'))
      },
      {
        exact: true,
        path: '/app/invoices',
        isAdmin: true,
        component: lazy(() => import('src/views/invoices'))
      },
      {
        exact: true,
        path: '/app/referral',
        isAdmin: true,
        component: lazy(() => import('src/views/referral'))
      },
      {
        exact: true,
        path: '/app/report',
        isAdmin: true,
        component: lazy(() => import('src/views/report'))
      },
      {
        exact: true,
        path: '/app/mailing',
        isAdmin: true,
        component: lazy(() => import('src/views/mailing'))
      },

      {
        exact: true,
        path: '/app/update-properties',
        isAdmin: true,
        component: lazy(() => import('src/views/update-properties'))
      },

      {
        exact: true,
        path: '/app/create-blog',
        isAdmin: true,
        component: lazy(() => import('src/views/blog/edit-blog'))
      },
      {
        exact: true,
        path: '/app/edit-blog/:id',
        isAdmin: true,
        component: lazy(() => import('src/views/blog/edit-blog'))
      },
      {
        exact: true,
        path: '/app/overview',
        isAdmin: true,
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/partner',
        isAdmin: true,
        component: lazy(() => import('src/views/partners'))
      },
      {
        exact: true,
        path: '/app/towns/appeals-seasons',
        isAdmin: true,
        component: lazy(() => import('src/views/townsAppealsSeasons'))
      },
      {
        exact: true,
        path: '/app/appeals',
        isAdmin: true,
        component: lazy(() => import('src/views/appeals/admin'))
      },
      {
        exact: true,
        path: '/app/appeals-draft',
        isAdmin: true,
        component: lazy(() => import('src/views/appeals/draft'))
      },
      {
        exact: true,
        path: '/app/manual-appeals',
        isAdmin: true,
        component: lazy(() => import('src/views/appeals/manual'))
      },
      {
        exact: true,
        path: '/app/towns/report',
        isAdmin: true,
        component: lazy(() => import('src/views/townsReports'))
      },
      {
        exact: true,
        path: '/app/admin/settings',
        isAdmin: true,
        component: lazy(() => import('src/views/settings/admin'))
      },
      {
        exact: true,
        path: '/app/admin/team-tasks',
        isAdmin: true,
        component: lazy(() => import('src/views/teamTasks'))
      },

      {
        exact: true,
        path: '/app/calculator/:PIN',
        isAdmin: true,
        component: lazy(() => import('src/views/calculator'))
      },
      {
        exact: true,
        path: '/app/my-appeals',
        isHomeOwner: true,
        component: lazy(() => import('src/views/appeals/homeOwner'))
      },
      {
        exact: true,
        path: '/app/appeals-details/:id',
        component: lazy(() => import('src/views/appealDetails'))
      },
      {
        exact: true,
        path: '/app/settings',
        isPartners: true,
        component: lazy(() => import('src/views/settings/partner'))
      },
      {
        exact: true,
        path: '/app/invitation-users',
        isPartners: true,
        component: lazy(() => import('src/views/PartnersInvitation'))
      },
      {
        exact: true,
        path: '/app/partner-appeals',
        isPartners: true,
        component: lazy(() => import('src/views/appeals/admin'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: lazy(() => import('src/components/Loader'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
